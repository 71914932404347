// FENCL VALIDATION MODULE v1.0

(function (global, $) { 
	"use strict";
	/* global $ */
	console.log("%c\u2713 %cValidation %cModule %cValidator is installed","font-size:2em","font-weight:bold; font-size:1.5em;color: #20c997;"," color: #444; font-weight:bold; font-size:1.5em;","font-weight:normal; font-size:1em;");

	// RETURN THE CONSTRUCTOR validator ON SELF EXECUTION
	let validator = function (formid) {
		return new validator.init(formid);
	};

	// EXPOSE FUNCTIONS TO THE EXTERNAL VARIABLE ON THE PROTOTYPICAL CHAIN
	validator.prototype = {

		// GET FUNCTIONS

		getForm: function () {
			return this._form;
		},

		getValidators: function () {
			return this._validators;
		},

		// SET FUNCTIONS

		// FUNCTION TO CONFIGURE VALIDATION METHODS
		setValidators: function (array) {
			this._validators = array;
		},

		// WORKING FUNCTIONS

		remove_errors: function (_invalid_tag, _parent_wrapper) {
			// OPTIONALLY OVERRIDE THE DEFAULT SETTINGS
			let invalid_tag = _invalid_tag || this._invalid_tag;
			let parent_wrapper = _parent_wrapper || this._parent_wrapper;
			// FUNCTIONALITY
			this._form.find("." + invalid_tag).each(function () {
				$(this).parents().find("." + parent_wrapper).remove();
				$(this).removeClass(invalid_tag);
			});
		},


		validate: function (_invalid_tag, _form) {
			// OPTIONALLY OVERRIDE THE DEFAULT SETTINGS
			let self = this;
			let form = _form || self._form;
			let invalid_tag = _invalid_tag || self._invalid_tag;

			setInputs(form, self);
			$.each(self._validators, function (item) {
				if (self._validators[item] == true) {
					item === "password"
						? validatePassword(item, invalid_tag, self._password_message)
						: item === "password_match"
							? passwordMatch(item, invalid_tag, self._match_message)
							: item === "checkbox"
								? validateCheckbox(_form.find("[data-type='" + item + "']"), invalid_tag, self._checkbox_message)
								: generalValidation(item, invalid_tag, self);
				}
			});
		},


		// CHECK FORM FOR ERRORS
		checkForm: function (_invalid_tag, successCallback, errorCallback) {
			// OPTIONALLY OVERRIDE THE DEFAULT SETTINGS
			let self = this;
			let form = self._form;
			let invalid_tag = _invalid_tag || self._invalid_tag;

			if (form.find("." + invalid_tag).length == 0) {
				successCallback(form);
			} else {
				errorCallback(form);
			}
		},

	};

	// INITIALIZE ANY DEFAULT VARIABLES WHICH CAN BE OVERRIDDEN LATER
	validator.init = function (formid) {
		var self = this;

		self._validators = {
			phone: false,
			email: false,
			zip: false,
			general: false,
			password: false,
			password_match: false, 
			checkbox: false
		};

		self._inputs;
		self._form = $(formid) || $("form");
		self._invalid_tag = "is-invalid";
		self._password_message = "Please enter a valid Password";
		self._match_message = "Passwords do not match";
		self._parent_wrapper = "invalid-feedback";
		self._checkbox_message = "Please accept the terms and conditions.";

		self._validation_regex = {
			phone: {regex: "\\([0-9]{3}\\)\\s[0-9]{3}-[0-9]{4}"},
			email: {regex: ".*@{1}.*{1}.*"},
			zip: {regex: "[0-9]{5}"},
			general: {regex: ".+"},
		};
		return self;
	};

	// LINK EXPOSED FUNCTIONS PROTOTYPE OBJECT TO INIT PROTOTYPAL CHAIN
	validator.init.prototype = validator.prototype;

	// SET GLOBAL OBJECT AND CREATE ALIAS
	global.validator = validator;

	// WORKING FUNCTIONS

	// FUNCTION TO CONFIGURE VALIDATION METHODS
	function setInputs(formElement, self) {
		self._inputs = formElement.find(":input[type!='hidden']");
	}

	function generalValidation(item, _invalid_tag, self) {
		self._inputs.filter("[data-type='" + item + "']").each(function () {
			// GET LABEL TEXT
			let label = $(this).parents(".form-group").find("label").html().replace(":", "");
			// CREATE REGEX PATTERN
			let pattern = new RegExp(self._validation_regex[item].regex);
			// IF MATCH IS FOUND
			if (!pattern.test($(this).val())) {
				// ADD ERROR MESSAGES & CLASSES
				$(this).addClass(_invalid_tag)
					.after("<div class='" + self._parent_wrapper + "'>Please enter a valid " + label + "</div>");
			}
		});
	}


	// ------------------------------------------------------
	// FUNCTIONS BELOW HERE STILL NEED TO BE REPAIRED TO WORK
	// ------------------------------------------------------


	// CHECK PASSWORD FUNCTION
	/**
	 * @param {String} invalid_tag should be class used to mark element invalid
	 */
	function validatePassword(data_type = "password", invalid_tag = _invalid_tag, error_message = _password_message) {
		// DATA-TYPE PASSWORD
		_inputs.filter("[data-type='." + data_type + "']").each(function () {
			// CREATE REGEX PATTERN
			let charUpper = new RegExp("[A-Z]+");
			let charLower = new RegExp("[a-z]+");
			let charNum = new RegExp("[0-9]+");
			let charLimit = new RegExp("[^ ]{8}");
			let charSpecial = new RegExp("[!@#$%^&*]+");

			if (!charUpper.test($(this).val()) && !charLower.test($(this).val()) && !charNum.test($(this).val()) && !charLimit.test($(this).val()) && !charSpecial.test($(this).val())) {
				// ADD ERROR MESSAGES & CLASSES
				$(this).addClass(invalid_tag)
					.after("<div class='" + _parent_wrapper + "'>" + error_message + "</div>");
			}
		});
	}

	// CHECK PASSWORD MATCH FUNCTION
	function passwordMatch(data_type = "password_match", invalid_tag = "is-invalid", error_message = "Passwords do not match") {
		// DATA-TYPE PASSWORD MATCH
		_inputs.filter("[data-type='" + data_type + "']").each(function () {

			if ($(this).val() !== $("[data-type='password']").val()) {
				// ADD ERROR MESSAGES & CLASSES
				$(this).addClass(invalid_tag)
					.after("<div class='" + _parent_wrapper + "'>" + error_message + "</div>");
			}
		});
	}

	// CHECK IF CHECKBOX IS CHECKED
	function validateCheckbox(checkboxElement = _form.find("data-type='checkbox'"), invalid_tag = _invalid_tag, message = _checkbox_message) {
		checkboxElement.each(function () {
			if (!checkboxElement.is(":checked")) {
				// ADD ERROR MESSAGES & CLASSES
				$(this).addClass(invalid_tag)
					.parents(".checkbox")
					.after("<div class='" + _parent_wrapper + "' style='display: block;'>" + message + "</div>");
			}
		});
	}


})(window, $);